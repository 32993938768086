import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import background2 from "./images/background2.webp";
import background4 from "./images/background4.webp";
import background5 from "./images/background5.webp";
import chairImage from "./images/chair.webp";
import deviceImage from "./images/device.webp";
import taglineImage from "./images/tagline.webp";
import iconElectricityImage from "./images/icon-electricity.png";
import iconRpImage from "./images/icon-rp.png";
import iconGlobeImage from "./images/icon-globe.png";

import logoImage from "./images/logo.webp";


import image01 from "./images/box-1.webp";
import image02 from "./images/box-2.webp";
import image03 from "./images/box-3.webp";
import image04 from "./images/box-4.webp";
import image05 from "./images/box-5.webp";
import image06 from "./images/box-6.webp";

import { Route, Link } from 'react-micro-router';
import { useMediaQuery } from 'react-responsive'

let boxes = [
    {
        title: 'ALAT PINTAR YANG AKURAT, HARGA TERJANGKAU',
        body: `
    HOMIe berbasis microcontroller, berisi algoritma pintar yang dapat mengatur penggunaan
    energi listrik di rumah anda. Dilengkapi dengan sensor yang akurat, HOMIe dapat
    menyeimbangkan antara kenyamanan dan biaya listrik yang serendah-rendahnya. Investasi
    HOMIe akan kembali melalui penghematan tagihan listrik dalam waktu kurang dari 1 tahun.
    `,
        image: image01
    },
    {
        title: 'DIPASANG DI RUMAH DENGAN MUDAH DAN CEPAT',
        body: `
    HOMIe dipasang berdampingan dengan box MCB di rumah anda. Pemasangan dan
penyetelan HOMIe dilakukan oleh Teknisi HOMIe tidak lebih dari 30 menit.
    `,
        image: image02
    },
    {
        title: 'MUDAH DIOPERASIKAN MENGGUNAKAN APLIKASI & TERHUBUNG DENGAN INTERNET',
        body: `
    HOMIe terhubung dengan Internet dan mudah dioperasikan menggunakan HP. Dengan
    mengunduh Apps (sementara tersedia di playstore), Anda dapat mengatur preferensi
    penggunaan HOMIe sesuai kebutuhan dan biaya listrik bulanan yang diinginkan.
    `,
        image: image03
    },
    {
        title: 'SET & FORGET – BERFUNGSI MENGATUR PRIORITAS PEMAKAIAN LISTRIK SESUAI DENGAN BUDGET YANG DIINGINKAN',
        body: `
    HOMIe mempermudah pengaturan biaya listrik sesuka anda. Dengan filosofi ‘set and
forget’, Anda cukup memasukan biaya maksimum dan skenario prioritas pemakaian alat
listrik . Selanjutnya HOMIe akan mengaturnya. Pada akhir bulan tagihan listrik akan sesuai
dengan budget, 100% LEBIH HEMAT.
    `,
        image: image04
    },
    {
        title: 'MENGAMANKAN DARI PEMADAMAN BERGILIR & MELESTARIKAN BUMI',
        body: `
    HOMIe bersama perusahaan Utilitas listrik dapat menghilangkan terjadinya pemadaman
bergilir. Dengan menggunakan HOMIe, anda menghemat penggunaan listrik, mengurangi
emisi gas yang tidak ramah lingkungan dan membantu melestarikan bumi.
    `,
        image: image05
    },
    {
        title: 'PEMASANGAN YANG SANGAT MUDAH',
        body: `
    Langkah #1 Instal HOMIe
Langkah #2 Instal Aplikasi
Langkah #3 Masukkan biaya maksimum, atur skenario dan prioritas penggunaan listrik yang
diinginkan. HOMIe akan mengurus semuanya
    `,
        image: image06
    },
]

function DesktopBoxes() {

    function createTextBox(box) {
        return <div style={{ float: "left", width: "50%", padding: 20, height: 210, position: "relative" }}>
            <div style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
                <div style={{ fontWeight: 900 }}>{box.title}</div>
                <div style={{ fontWeight: 100, marginTop: 5, fontSize: 13, paddingRight: 5 }}>{box.body}
                </div>
            </div>
        </div>
    }

    function createImageBox(box) {
        return <div style={{ float: "left", width: "50%" }}>
            <img src={box.image} style={{ width: 600 }} />
        </div>;
    }

    return <div>
        <div style={{}}>
            {
                boxes.map((box, index) =>
                    <div style={{ maxWidth: 1200, margin: "0 auto" }}>
                        {index % 2 == 0 ?
                            <div>
                                {createTextBox(box)}
                                {createImageBox(box)}
                                <div style={{ clear: "both" }}></div>
                            </div> :
                            <div>
                                {createImageBox(box)}
                                {createTextBox(box)}
                                <div style={{ clear: "both" }}></div>
                            </div>
                        }
                    </div>
                )
            }
        </div>
    </div>;
}


function MobileBoxes() {

    function createTextBox(box) {
        return <div style={{ padding: 15 }}>
            <div style={{ fontWeight: 900 }}>{box.title}</div>
            <div style={{ fontWeight: 100, marginTop: 5, fontSize: 13, paddingRight: 5 }}>{box.body}
            </div>
        </div>;

    }

    function createImageBox(box) {
        return <img src={box.image} style={{ width: "100%" }} />

    }
    return <div>
        <div style={{}}>
            {
                boxes.map((box, index) =>
                    <div style={{ maxWidth: 1200, margin: "0 auto" }}>
                        <div>

                            {createImageBox(box)}
                            {createTextBox(box)}
                        </div>
                    </div>
                )
            }
        </div>
    </div>;
}

function MainFold() {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 780px)'
    })


    let topHero = isDesktopOrLaptop ?
        <>
            <div style={{ position: "absolute", left: "20%", top: 200 }} >
                <img src={deviceImage} style={{ width: 200, float: "left" }} />
                <img src={taglineImage} style={{ width: 400, float: "left", marginLeft: 20 }} />
            </div>

            <img style={{ position: "absolute", right: "10%", top: 200, width: 400 }} src={chairImage} />
        </> :
        <>
            <div style={{ position: "absolute", left: "10%", top: 150 }} >
                <img src={deviceImage} style={{ width: 80, float: "left" }} />
                <img src={taglineImage} style={{ width: 200, float: "left", marginLeft: 20 }} />
            </div>

            <img style={{ position: "absolute", right: "0%", top: 250, width: 300 }} src={chairImage} />
        </>;

    let items = [
        {
            text: "Mengatur penggunaan listrik sesuai keinginan dan kebutuhan",
            image: iconElectricityImage
        },
        {
            text: "Menghemat Tagihan",
            image: iconRpImage
        },
        {
            text: "Hemat Energi Selamatkan Bumi",
            image: iconGlobeImage
        }
    ]

    return (

        <>
            <div style={{ height: isDesktopOrLaptop ? 700 : 750, fontFamily: "system-ui, sans-serif", position: "relative", lineHeight: "1.4", backgroundImage: `url(${background2}) `, backgroundSize: "cover" }}>
                {topHero}
                <div style={{ position: "absolute", bottom: 20, left: "50%", width: isDesktopOrLaptop ? 1001 : "100%", transform: "translateX(-50%)" }}>
                    <div style={{ float: "left", width: 310, marginLeft: 10, color: "#fff" }}>
                        <img style={{ width: 40, float: "left", marginTop: 0 }} src={iconElectricityImage} />
                        <div style={{ float: "left", width: 250, marginTop: 15, marginLeft: 10, verticalAlign: "middle" }}>Mengatur penggunaan listrik sesuai keinginan dan kebutuhan</div>
                    </div>
                    <div style={{ float: "left", width: 310, color: "#fff" }}>
                        <img style={{ width: 40, float: "left", marginLeft: 10, marginTop: 20 }} src={iconRpImage} />
                        <div style={{ float: "left", width: 250, marginTop: 25, marginLeft: 10, verticalAlign: "middle" }}>Menghemat Tagihan</div>
                    </div>
                    <div style={{ float: "left", width: 310, color: "#fff" }}>
                        <img style={{ width: 40, float: "left", marginLeft: 10, marginTop: 15 }} src={iconGlobeImage} />
                        <div style={{ float: "left", width: 250, marginTop: 25, marginLeft: 10, verticalAlign: "middle" }}>Hemat Energi Selamatkan Bumi</div>
                    </div>
                </div>
            </div>
        </>
    );
}

function SecondFold() {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 780px)'
    })


    let items = [
        { text: "Alat pintar yang akurat dengan biaya terjangkau" },
        { text: "Berfungsi mengatur prioritas pemakaian listrik sesuai budget yg diinginkan" },
        { text: " Set & Forget" },
        { text: "Mudah dioperasikan menggunakan aplikasi" },
        { text: "Dipasang dirumah dengan mudah & cepat" },
        { text: "Mengamankan Dari Pemadaman Bergilir & Melestarikan Bumi" },
    ]

    let children = isDesktopOrLaptop ?
        items.map((item, index) => {
            return <>
                <div style={{ float: "left", width: 200, color: "#fff", textAlign: "center", padding: "0 15" }}>
                    {index > 0 && <div style={{ float: "left", color: "#fff", fontWeight: "bold" }}>&bull;</div>}
                    {item.text}
                </div>
            </>
        })
        :

        items.map((item, index) => {
            return <>
                <div style={{ padding: "0 15", color: "#fff", padding: "10px 0", fontWeight: "bold" }}>
                    &bull; {item.text}
                </div>
            </>
        });

    return (

        <>
            {isDesktopOrLaptop ?
                <div style={{ fontFamily: "system-ui, sans-serif", position: "relative", height: 800, lineHeight: "1.4", backgroundImage: `url(${background5}) `, backgroundSize: "cover", width: "100%" }}>

                    <div style={{ position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.5)", bottom: 0, padding: "20px", width: "100%" }}>
                        <div style={{ position: "relative", left: "50%", maxWidth: 1300, transform: "translateX(-50%)" }}>
                            {items.map((item, index) => {
                                return <>
                                    <div style={{ float: "left", width: 200, color: "#fff", textAlign: "center", padding: "0 15" }}>
                                        {index > 0 && <div style={{ float: "left", color: "#fff", fontWeight: "bold" }}>&bull;</div>}
                                        {item.text}
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                </div>
                :
                <div style={{ position: "relative" }}>
                    <img src={background5} style={{ width: "100%" }} />
                    <div style={{ backgroundColor: "rgba(0, 0, 0, 0.8)", padding: "20px", width: "100%" }}>
                        <div style={{ position: "relative", left: "50%", maxWidth: 1300, transform: "translateX(-50%)" }}>
                            {items.map((item, index) => {
                                return <>
                                    <div style={{ padding: "0 15", color: "#fff", padding: "10px 0", fontWeight: "bold" }}>
                                        &bull; {item.text}
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

function Header() {

    return (
        <div style={{ padding: 8, zIndex: 1000, background: "#444", minWidth: 220, color: "white", margin: "0 auto", position: "fixed", top: 0, left: "50%", transform: "translateX(-50%)" }}>
            <Link to="/"><img style={{ width: 50, float: "left" }} src={logoImage} /></Link>
            <Link to="/about-us"><div style={{ float: "left", marginTop: 10, marginLeft: 15, padding: "5px 15px", borderLeft: "1px solid #ccc" }} href="#">Tentang Kami</div></Link>
            <div style={{ clear: "both" }}></div>
        </div>
    );
}


function HomePage() {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    return (

        <div >
            <Header />
            <MainFold />
            <SecondFold />
            {isDesktopOrLaptop ? <DesktopBoxes /> : <MobileBoxes />}
        </div >
    );
}

function AboutUsPage() {

    return (

        <div>
            <Header />
            <div style={{ lineHeight: "1.4", backgroundImage: `url(${background4}) `, backgroundSize: "cover", backgroundPosition: "center" }}>
                <div style={{ minWidth: 200, maxWidth: 800, margin: "0 auto", color: "white", textAlign: "center" }}>

                    <div style={{ height: 100 }}></div>
                    <div style={{ fontSize: 30, fontWeight: "bold" }}>Tentang Kami</div>
                    <br />
                    <div style={{ fontWeight: "bold", padding: 10 }}>

                        <p>
                            Halo, saya Galih seorang insinyur sipil.
                            Berbeda dengan jalur pendidikan dan karir yang dijalani selama 32 tahun, saya memiliki
                            passion yang kuat terhadap hal-hal yang bernuansa high tech terutama teknik dan mesin
                            elektronika.
                        </p>

                        <br />
                        <p>
                            Sejak usia 14 tahun, saya sudah merancang produk elektronika pertama yaitu pemancar
                            radio dengan antena yang dipasang di tiang bambu yang cukup tinggi di belakang rumah.
                            Pemancar tersebut mampu memancarkan radio dengan frekuensi tertentu dan jarak yang
                            cukup jauh. Bukan kepalang senangnya saya saat itu. Walau pada akhirnya produk
                            elektronik pertama tersebut berakhir dengan disita petugas keamanan karena dinilai
                            mengganggu siaran televisi penghuni kompleks.
                        </p>

                        <br />
                        <p>
                            Sampai akhirnya di tahun 2015, saya membuka kembali kotak pandora dalam pikiran dan
                            menyadari bahwa "Passion is Everything". Saya memutuskan mengundurkan diri dari jabatan
                            karir sebagai CEO perusahaan properti ternama di Indonesia dan memulai perjalanan yang
                            baru bersama Gandira, pemuda lulusan UC Berkeley USA jurusan Cognitive Science.
                            Ilmu kognitif (cognitive science) mempelajari prinsip-prinsip kecerdasan dari metode
                            psikologi, linguistik, filosofi, ilmu komputer, kecerdasan buatan, ilmu syaraf dan antropologi
                            dengan tujuan untuk menghasilkan pengembangan perangkat cerdas (Smart Things).
                        </p>

                        <br />
                        <p>
                            Suatu perpaduan yang cukup unik karena selain ilmu kognitif, Gandira juga memiliki
                            ketertarikan yang kuat terhadap computer science sekaligus ilmu ekonomi -- yang
                            dimulainya sejak sekolah menegah pertama dengan memenangkan olimpiade science di
                            bidang ekonomi tingkat Nasional.
                        </p>

                        <br />
                        <p>
                            Beragam latar belakang dan passion inilah yang mendorong kami mendirikan start up -
                            AKAL - yang memproduksi alat-alat pintar berbasis artificial intelligence, machine learning
                            dan algoritma pintar untuk membantu meningkatkan efektifitas dan efisiensi alat- alat
                            kelistrikan yang sudah digunakan masyarakat sebelumnya serta secara kognitif meniru
                            kinerja otak untuk melakukan aktivitas penggunaan sehari-hari.
                        </p>

                        <br />
                        <p>
                            Produk pertama kami, HOMIe merupakan alat pintar pengatur pemakaian energi listrik
                            rumah tangga sekaligus penghemat tagihan listrik. Penggunaan HOMIe dalam jangka
                            panjang dapat membantu perusahaan utilitas listrik atau perusahaan lainnya untuk lebih
                            mengoptimalkan energi listrik, menciptakan tujuan keberlangsungan energi yang lebih
                            panjang, dan bumi yang lebih baik bagi kita bersama.
                        </p>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
}


function App() {
    return <>
        <Route path="/" exact>
            <HomePage />
        </Route>
        <Route path="/about-us">
            <AboutUsPage />
        </Route>

    </>;
}

ReactDOM.render(<App />, window.root);